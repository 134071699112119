import React from "react";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import UnorderedList from "~/components/List/Unordered";

const Page = () => {
  return (
    <LessonLayout
      current_step={5}
      lesson={"Lesson 5"}
      color={"hs"}
      total_count={7}
      module_title="Putting Your Rubric to Work"
    >
      <ContentWrapper>
        <H2 underline>Step 5</H2>

        <p>Trade one of the health-related items that you evaluated with one that your teammate chose. Also share the question you asked so your teammate will use that same question.</p>
        <p>Use your rubric to evaluate your classmate's information item and question. When each of you finish scoring the other person's information, compare your scores and the reasons for those scores.</p>
        <p>Discuss your outcomes with your classmate.</p>

        <UnorderedList>
          <li>How similar or different were your scores from those of your classmate? It does not matter if your scores match, but you should each be able to explain why you gave your score.</li>
          <li>If your scores are different, does that mean that someone is right and the other person is wrong? Explain your answer.</li>
          <li>What were the strengths or weaknesses of the information source?</li>
        </UnorderedList>

        <p>If you wish, you can make more changes to your rubric if you think of new criteria that would be helpful.</p>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

